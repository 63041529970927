<template>
  <div class="d-flex align-center flex-grow-1">
    <div :class="[display.smAndDown ? 'mr-2': 'mr-3']">
      <EmAvatar
        :name="name"
        :phone-number="phoneNumber"
        :small="display.smAndDown"
      />
    </div>
    <div :data-cy="dataCy">
      <div v-if="name">
        <EmBody2 bold :data-cy="`${dataCy}--name`" class="max-lines-1">
          {{ name }}
        </EmBody2>
      </div>
      <div>
        <EmPhoneNumber
          :data-cy="`${dataCy}--phone`"
          :phone="phoneNumber"
        >
          <template #phone>
            <EmPhoneNumberName
              :name="name"
              :phone-number="phoneNumber"
            />
          </template>
        </EmPhoneNumber>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
defineProps({
  name: { type: String, default: null },
  phoneNumber: { type: String, default: null },
  dataCy: { type: String, default: null }
})
</script>
