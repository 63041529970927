<template>
  <v-menu :close-on-content-click="false" width="300">
    <template #activator="{ props: menuProps }">
      <EmIconButton
        icon="mdi-volume-high"
        title="Audio Settings"
        v-bind="menuProps"
      />
    </template>
    <template #default>
      <v-card>
        <v-card-text>
          <div class="mb-4">
            <EmSelect
              id="input-source"
              v-model="audioControls.inputSource"
              :items="audioControls.availableInputSources"
              item-title="label"
              item-value="deviceId"
              label="Input Source"
              hide-details
              @update:model-value="audioControls.setInputSource"
            />
          </div>
          <div>
            <EmSelect
              id="output-source"
              v-model="audioControls.outputSource"
              :items="audioControls.availableOutputSources"
              item-title="label"
              item-value="deviceId"
              label="Speaker Source"
              hide-details
              @update:model-value="audioControls.setOutputSource"
            />
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-menu>
</template>

<script setup>
const audioControls = useAudioControlsStore()
onMounted(async () => {
  await audioControls.getAudioSources()
})
</script>
