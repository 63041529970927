<template>
  <div class="width-100 d-flex justify-space-between align-center">
    <CallAudioControlsMenu />
    <EmIconButton
      icon="mdi-phone-hangup"
      size="large"
      color="white"
      title="End Call"
      button-class="bg-error"
      @click="emits('end-call')"
    />
    <EmIconButton
      icon="mdi-microphone-off"
      size="large"
      title="Mute"
    />
  </div>
</template>

<script setup>
const emits = defineEmits(['end-call'])
</script>
