import { defineStore } from 'pinia'
import { Service } from '@/services/helpers/ServiceHelper'

export const useAudioControlsStore = defineStore('audioControls', () => {
  const initialized = ref(false)
  const availableInputSources = ref<MediaDeviceInfo[]>([])
  const inputSource = ref<string | null>(null)
  const setInputSource = async (deviceId: string) => {
    await Service.api.callService?.setInputSource(deviceId)
  }
  const availableOutputSources = ref<MediaDeviceInfo[]>([])
  const outputSource = ref<string | null>(null)
  const setOutputSource = async (deviceId: string) => {
    await Service.api.callService?.setOutputSource(deviceId)
  }
  const getAudioSources = async () => {
    if (initialized.value) { return }
    initialized.value = true
    const audioSources = await Service.api.callService?.getAudioSources()
    availableInputSources.value = audioSources?.availableInputSources || []
    inputSource.value = audioSources?.inputSource || null
    availableOutputSources.value = audioSources?.availableOutputSources || []
    outputSource.value = audioSources?.outputSource || null
  }
  return {
    availableInputSources,
    inputSource,
    availableOutputSources,
    outputSource,
    setInputSource,
    setOutputSource,
    getAudioSources
  }
})
